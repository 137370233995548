@import "fuelux-core.less";
.fuelux {

	.picker {
		display: inline-block;
		position: relative;

		&[data-ellipsis="true"] {
			&.showing {
				input.picker-field {
					overflow: visible;
					text-overflow: clip;
					white-space: normal;
				}
			}

			input.picker-field {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;

				&::-ms-clear {
					display:none;
				}
			}

			textarea.picker-field {
				&[readonly] {
					overflow: hidden;
				}
			}
		}

		&.showing {
			.picker-footer,
			.picker-header,
			.picker-popup {
				display: block;
				z-index: 1;
			}

			input.picker-field, textarea.picker-field {
				background: @true-white;
				border: 1px solid @gray80;
				box-shadow: none;
				position: relative;
				z-index: 1;
			}
		}

		input.picker-field, textarea.picker-field {
			resize: none;

			&[readonly] {
				background: @true-white;
				cursor: auto;

				&.glass {
					background: none;

					&:hover {
						background: @info-background;
						cursor: pointer;
					}
				}
			}

			&:focus {
				border: 1px solid @gray80;
				box-shadow: none;
			}
		}

		&-cancel {
			font-size: 12px;
			margin-right: 4px;
			vertical-align: middle;
		}

		&-footer,
		&-header {
			display: none;
			left: 0;
			line-height: 1;
			right: 0;
		}

		&-footer {
			padding: 8px 10px 8px 0;
			text-align: right;
			bottom: 0;
			position: absolute;
		}

		&-header {
			height: 31px;
			padding: 8px 0 0px 10px;

			h1, h2, h3, h4, h5, h6 {
				margin: 0;
			}
		}

		&-popup {
			background: @info-background;
			background-clip: padding-box;
			border: 1px solid @gray80;
			border-radius: 4px;
			box-shadow: 0 0 0 1px @true-white inset;
			display: none;
			position: absolute;
			padding-left: 6px;
			padding-right: 6px;
			height: 234px;
			width: 350px;
			margin: 4px 0;

		}

		.picker-body.well {
			background-color: #fff;
			overflow: scroll;
			padding: 0;
			height: 165px;
		}

		.tree {
			border: none;
		}

		.glass {
			background: transparent;
			border: 1px solid @true-white;
			box-shadow: none;

			&:hover {
				background: @info-background;
				border-color: @focus-color;
				cursor: pointer;

				&[disabled] {
					background: transparent;
					border-color: @true-white;
					cursor: not-allowed;
				}
			}

			&:focus {
				background: @true-white;
				border-color: @focus-color;
				box-shadow: inset 0 1px 1px fade(@true-black, 75%), 0 0 8px fade(@focus-color, 60%);
				cursor: auto;

				&[disabled] {
					background: transparent;
					border-color: @true-white;
					cursor: not-allowed;
				}
			}

			&[disabled] {
				cursor: not-allowed;
			}
		}
	}

}