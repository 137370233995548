@import "fuelux-core.less";
.fuelux {

	 .combobox {
		&.disabled {
			.input-group-btn {
				cursor: not-allowed;
			}
		}
		input {
			&::-ms-clear {
				display:none;
			}
		}
		.dropdown-menu > li.selected > a {
			color: #262626;
			text-decoration: none;
			background-color: #f5f5f5;
		}

		.dropdown-menu > li > em {
			display: block;
			padding: 3px 20px;
			clear: both;
			font-weight: normal;
			line-height: 1.42857143;
			color: #333;
			white-space: nowrap;
		}
	}



}
