@charset "UTF-8";


// Core variables, mixins, and icons
@import "fuelux-core";	// Modify this for custom colors, font-sizes, etc

// Fuel UX controls
@import "icons.less";
@import "forms.less"; // forms.less contains code shared between form elements.
@import "checkbox.less"; // Checkbox styles that will be deprecated.
@import "checkbox-no-js.less"; // Checkbox styles that are not dependant upon custom javascript controls.
@import "radio.less"; // Radio styles that will be deprecated.
@import "radio-no-js.less";  // Radio styles that are not dependant upon custom javascript controls.
@import "combobox.less";
@import "datepicker.less";
@import "infinite-scroll.less";
@import "intelligent-dropdown.less";
@import "loader.less";
@import "pillbox.less";
@import "placard.less";
@import "repeater.less";
@import "repeater-list.less";
@import "repeater-thumbnail.less";
@import "spinbox.less";
@import "scheduler.less";
@import "search.less";
@import "selectlist.less";
@import "picker.less";
@import "tree.less";
@import "wizard.less";
@import "utility.less";


