@import "fuelux-core.less";
// For clearing floats
.clearfix {
	*zoom: 1;
	&:before,
	&:after {
		display: table;
		content: "";
		// Fixes Opera/contenteditable bug:
		// http://nicolasgallagher.com/micro-clearfix-hack/#comment-36952
		line-height: 0;
	}
	&:after {
		clear: both;
	}
}

#fuelux {

	// Truncate
	.truncate() {
		max-width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	// Single side border-radius
	.border-top-radius(@radius) {
		border-top-right-radius: @radius;
		border-top-left-radius: @radius;
	}
	.border-right-radius(@radius) {
		border-bottom-right-radius: @radius;
	 	border-top-right-radius: @radius;
	}
	.border-bottom-radius(@radius) {
		border-bottom-right-radius: @radius;
		border-bottom-left-radius: @radius;
	}
	.border-left-radius(@radius) {
		border-bottom-left-radius: @radius;
		border-top-left-radius: @radius;
	}

	// Gradient Bar Colors for buttons and alerts
	.gradient-bar(@primary-color, @secondary-color, @text-color: #fff, @text-shadow: 0 -1px 0 rgba(0,0,0,.25)) {
		color: @text-color;
		text-shadow: @text-shadow;
		#gradient > .vertical(@primary-color, @secondary-color);
		border-color: @secondary-color @secondary-color darken(@secondary-color, 15%);
		border-color: rgba(0,0,0,.1) rgba(0,0,0,.1) fadein(rgba(0,0,0,.1), 15%);
	}

	// Gradients
	.vertical(@start-color: @gray33, @end-color: @gray20) {
		background-color: mix(@start-color, @end-color, 60%);
		background-image: -moz-linear-gradient(top, @start-color, @end-color); // FF 3.6+
		background-image: -webkit-gradient(linear, 0 0, 0 100%, from(@start-color), to(@end-color)); // Safari 4+, Chrome 2+
		background-image: -webkit-linear-gradient(top, @start-color, @end-color); // Safari 5.1+, Chrome 10+
		background-image: -o-linear-gradient(top, @start-color, @end-color); // Opera 11.10
		background-image: linear-gradient(to bottom, @start-color, @end-color); // Standard, IE10
		background-repeat: repeat-x;
		filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)",argb(@start-color),argb(@end-color))); // IE9 and down
	}

	// Icon Base Styles
	.icon-base-styles(@font-family: "fuelux"){
		font-family: @font-family !important;
		font-style: normal !important;
		font-weight: normal !important;
		font-variant: normal !important;
		text-transform: none !important;
		line-height: 1;
		speak: none;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	// Disables selection of text
	.no-selection-allowed(){
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
	}

	// Opacity
	.opacity(@opacity) {
		opacity: @opacity / 100;
		filter: ~"alpha(opacity=@{opacity})";
	}

	// Helpers
	// -------------------------
	.data-label() {
	  text-transform: uppercase;
	  font-size: 10px;
	  margin-bottom: 2px;
	  text-shadow: 0 1px @true-white;
	  color: @gray40;
	}

	.data-value() {
	  font-size: 14px;
	}

	.section-heading() {
	  font-size: 16px;
	  color: @gray20;
	  margin: 0 0 10px;
	  padding: 0;
	  text-shadow: 0 1px @true-white;
	}

	.text-emphasis-variant(@color) {
		color: @color;
		&:hover {
			color: darken(@color, 10%);
		}
	}

	.vertical-align() {
		margin: auto;
		position: absolute;
		top: 0;
		bottom: 0;
	}

}
